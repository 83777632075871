<template>
  <b-container fluid class="account-headers accounts-tabs-height">
    <b-row>
      <b-col cols="1" class="text-center">
        <b-img src="/img/account-icons.svg"></b-img>
      </b-col>
      <b-col cols="11">
        <b-row>
          <b-col cols="12">
            <h2>{{$t("profile.infotexts.Notifications-title")}}</h2>
            <p>
              {{$t("profile.infotexts.Notifications")}}
            </p>
            <hr />
          </b-col>
        </b-row>
        <div class="row justify-content-between">
          <div v-if="loading" class="col-8">
            <loading :loading="loading" />
          </div>
          <div v-if="!loading" class="col-6">
            <div class="notification-setting-content">
              <div>
                <h3> {{$t("profile.contents.notifications.email.title")}}</h3>
              
                <div class="item">
                  <b-form-checkbox
                    id="emailFollow"
                    v-model="form.email.follow"
                    name="emailFollow"
                    :unchecked-value="false"
                  >
                   {{$t("profile.contents.notifications.email.check1")}}
                  </b-form-checkbox>
                </div>
                <div class="item">
                  <b-form-checkbox
                    id="emailMessage"
                    v-model="form.email.message"
                    name="emailMessage"
                    :unchecked-value="false"
                  >
                   {{$t("profile.contents.notifications.email.check2")}}
                  </b-form-checkbox>
                </div>
                <div class="item">
                  <b-form-checkbox
                    id="emailcp"
                    v-model="form.email.connections_product"
                    name="emailcp"
                    :unchecked-value="false"
                  >
                   {{$t("profile.contents.notifications.email.check4")}}
                  </b-form-checkbox>
                </div>
                <div class="item">
                  <b-form-checkbox
                    id="emailci"
                    v-model="form.email.connections_inquiry"
                    name="emailci"
                    :unchecked-value="false"
                  >
                   {{$t("profile.contents.notifications.email.check5")}}
                  </b-form-checkbox>
                </div>
                <div class="item">
                  <b-form-checkbox
                    id="emailCon"
                    v-model="form.email.connectter"
                    name="emailCon"
                    :unchecked-value="false"
                  >
                   {{$t("profile.contents.notifications.email.check3")}}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
            <div class="d-flex text-left" style="margin-top: 40px">
              <button
                @click.prevent="saveNotify"
                class="btn btn-outline-primary"
              >
                {{$t("buttons.savechanges")}}
              </button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>

  <!--<div class="row justify-content-between">
    <div v-if="loading" class="col-8">
      <loading :loading="loading" />
    </div>
    <div v-if="!loading" class="col-6">
      <div class="notification-setting-content">
          <div>
            <h3>E-Mail Notifications</h3>
            <div class="item">
              <b-form-checkbox
                  id="emailFollow"
                  v-model="form.email.follow"
                  name="emailFollow"
                  unchecked-value="not_accepted"
              >
                Notify when someone follows or unfollows me
              </b-form-checkbox>
            </div>
            <div class="item">
              <b-form-checkbox
                  id="emailMessage"
                  v-model="form.email.message"
                  name="emailMessage"
                  unchecked-value="not_accepted"
              >
                Notify when I get a message
              </b-form-checkbox>
            </div>
            <div class="item">
              <b-form-checkbox
                  id="emailCon"
                  v-model="form.email.connectter"
                  name="emailCon"
                  unchecked-value="not_accepted"
              >
                Notify me about news & updates of Connectter
              </b-form-checkbox>
            </div>
          </div>
        <hr class="my-5" />
        <div>
          <h3>Desktop Notifications</h3>
          <div class="item">
            <b-form-checkbox
                id="desktopFollow"
                v-model="form.desktop.follow"
                name="desktopFollow"
            >
              Notify when someone follows or unfollows me
            </b-form-checkbox>
          </div>
          <div class="item">
            <b-form-checkbox
                id="desktopMessage"
                v-model="form.desktop.message"
                name="desktopMessage"
            >
              Notify when I get a message
            </b-form-checkbox>
          </div>
         <div class="item">
           <b-form-checkbox
               id="desktopCon"
               v-model="form.desktop.connectter"
               name="desktopCon"
           >
             Notify me about news & updates of Connectter
           </b-form-checkbox>
         </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button @click.prevent="saveNotify" class="btn btn-outline-primary">
          Save Changes
        </button>
      </div>
    </div>
    <div class="col-4">
      <div class="setting-info-card">
        <div class="icon">
          <v-icon :icon="['fac', 'bulb']" />
        </div>
        <h3 class="title">
          You have the control
        </h3>
        <hr class="w-75" />
        <p>
          From this page, you can turn on and off the e-mail newsletters we send you, as well as notifications when activity happens that you might be interested in.
        </p>
      </div>
    </div>
  </div> -->
</template>

<script>
import getNotify from "@/graphql/me/profile/getNotify.graphql";
import updateNotify from "@/graphql/me/profile/updateNotify.graphql";
export default {
  name: "notifyTab",
  data() {
    return {
      loading: true,
      status: false,
      form: {
        sound: false,
        email: {
          follow: false,
          message: false,
          connectter: false,
          connections_product: false,
          connections_inquiry: false
        },
        desktop: {
          follow: false,
          message: false,
          connectter: false,
          connections_product: false,
          connections_inquiry: false
        },
      },
    };
  },
  methods: {
    async getNotify() {
      this.loading = true;
      let data = await this.$apollo.query(getNotify);
      let response = data("**.get", true);
      this.form = response;
      this.loading = false;
    },
    async saveNotify() {
      try {
        this.loading = true;
        await this.$apollo.mutate(updateNotify, { notify: this.form });
        return this.$bvToast.toast(this.$t("Toaster.s-ful"), {
          title: this.$t("Toaster.success-title"),
          variant: "success",
          autoHideDelay: 1350,
        });
      } catch (e) {
        
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getNotify();
  },
};
</script>

<style scoped>
</style>